import React, { useState, useRef } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PieController,
  Colors,
} from 'chart.js';
import { IMeetingInfo } from '../../types';
import { motion, AnimatePresence } from 'framer-motion';
import {
  createBarData,
  createBarOptions,
  createPieData,
  createPieOptions,
} from './charts/chartConfig';
import { externalLabelsPlugin } from './charts/plugins/ExternalLabelsPlugin';
import ChartTypeToggle from './charts/ChartTypeToggle';
import AvatarGroupPopover from './AvatarGroupPopover';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PieController,
  Title,
  Tooltip,
  Legend,
  Colors
);

interface GraphViewProps {
  meetings: IMeetingInfo[];
}

const GraphView: React.FC<GraphViewProps> = ({ meetings }) => {
  const [selectedMeeting, setSelectedMeeting] = useState<IMeetingInfo | null>(null);
  const [chartType, setChartType] = useState<'bar' | 'pie'>('bar');
  const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
  const chartRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const uniqueColors = [
    { bg: '#4F46E5', text: '#ffffff' }, // Indigo
    { bg: '#0EA5E9', text: '#ffffff' }, // Sky
    { bg: '#8B5CF6', text: '#ffffff' }, // Purple
    { bg: '#EC4899', text: '#ffffff' }, // Pink
    { bg: '#6366F1', text: '#ffffff' }, // Indigo
    { bg: '#A855F7', text: '#ffffff' }, // Purple
    { bg: '#14B8A6', text: '#ffffff' }, // Teal
    { bg: '#F43F5E', text: '#ffffff' }, // Rose
    { bg: '#8B5CF6', text: '#ffffff' }, // Violet
    { bg: '#06B6D4', text: '#ffffff' }, // Cyan
  ];

  const barData = createBarData(meetings);
  const barOptions = createBarOptions(meetings);
  const pieData = createPieData(meetings);
  const pieOptions = createPieOptions(meetings);

  const topics = Array.from(new Set(meetings.map((m) => m.interestName)));
  const topicOptions = topics.map((topic, index) => ({
    value: topic,
    label: topic,
    color: {
      bg: uniqueColors[index % uniqueColors.length].bg,
      text: uniqueColors[index % uniqueColors.length].text,
    },
  }));

  const filteredMeetings =
    selectedTopics.length > 0
      ? meetings.filter((meeting) =>
        selectedTopics.some((topic) => topic.value === meeting.interestName)
      )
      : meetings;

  return (
    <div className="space-y-6">
      <div className="flex flex-wrap gap-4 justify-between items-center">
        <ChartTypeToggle chartType={chartType} setChartType={setChartType} />

      </div>

      <div
        ref={containerRef}
        className="relative h-[600px] w-full bg-white p-4 rounded-lg shadow-lg"
      >
        {chartType === 'bar' ? (
          <Bar
            ref={chartRef}
            data={barData}
            options={barOptions}
            key="bar-chart"
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <div className="w-[95%] h-[100%]">
              <Pie
                ref={chartRef}
                data={pieData}
                options={pieOptions}
                key="pie-chart"
                plugins={[externalLabelsPlugin]}
              />
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        {selectedMeeting && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 flex items-center justify-center z-50"
          >
            <AvatarGroupPopover
              attendees={selectedMeeting.attendees}
              onClose={() => setSelectedMeeting(null)}
              companyId={selectedMeeting.companyId}
              interestId={selectedMeeting.interestId}
              interestName={selectedMeeting.interestName}
              stats={{
                total: selectedMeeting.attendees.length,
                attended: selectedMeeting.attendees.filter((a) => a.didAttended)
                  .length,
                notAttended: selectedMeeting.attendees.filter(
                  (a) => !a.didAttended
                ).length,
                accepted: selectedMeeting.attendees.filter((a) => a.didAccepted)
                  .length,
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GraphView;