import React from 'react';
import { IMeetingInfo } from '../../types';
import { FileJson, FileText, FileSpreadsheet, Database } from 'lucide-react';
import { utils, writeFile } from 'xlsx-js-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { generateMeetingStats, generateAttendeeRow, applyExcelStyles } from '../../utils/exportHelpers';
import { excelStyles, pdfStyles } from '../../utils/exportStyles';
import { generateCSVContent } from '../../utils/csvExporter';

// Define PubSub type
type PubSub = any;

// Extend jsPDF with autoTable method
interface ExtendedInternal {
  events: PubSub;
  scaleFactor: number;
  pageSize: {
    width: number;
    getWidth: () => number;
    height: number;
    getHeight: () => number;
  };
  pages: number[];
  getEncryptor(objectId: number): (data: string) => string;
  getNumberOfPages: () => number;
}

interface AutoTableJsPDF extends jsPDF {
  autoTable: (options: UserOptions) => AutoTableJsPDF;
  lastAutoTable?: {
    finalY: number;
  };
  internal: ExtendedInternal;
}

interface DownloadMenuProps {
  meetingInfo: IMeetingInfo;
  onClose: () => void;
  isAvatarGroupMenu?: boolean;
  isGlobalDownload?: boolean;
}

const DownloadMenu: React.FC<DownloadMenuProps> = ({
  meetingInfo,
  onClose,
  isAvatarGroupMenu = false,
  isGlobalDownload = false
}) => {
  const handleDownload = async (action: () => void, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await action();
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const downloadJSON = async () => {
    const jsonString = JSON.stringify(meetingInfo, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `meeting_${meetingInfo.meetingId}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    onClose();
  };


  const extractTime = (datetime: string): string | null => {
    const dateString: string = datetime.replace(' at ', ' ');
    const date: Date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format");
      return null;
    }
    const hours: number = date.getHours();
    const minutes: number = date.getMinutes();
    const seconds: number = date.getSeconds();
    const ampm: string = hours >= 12 ? 'PM' : 'AM';
    const formattedTime: string = `${hours % 12 || 12}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${ampm}`;
    return formattedTime;
  };

  const downloadCSV = async () => {
    const csvContent = generateCSVContent(meetingInfo);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `meeting_${meetingInfo.meetingId}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    onClose();
  };

  const downloadExcel = () => {
    const wb = utils.book_new();
    const stats = generateMeetingStats(meetingInfo);

    // Meeting Summary Sheet
    const summaryHeaders = [
      { v: 'Topic', t: 's', s: excelStyles.header },
      { v: 'Total Participants', t: 's', s: excelStyles.header },
      { v: 'Attended', t: 's', s: excelStyles.header },
      { v: 'Not Attended', t: 's', s: excelStyles.header },
      { v: 'Accepted', t: 's', s: excelStyles.header },
      { v: 'Attendance Rate', t: 's', s: excelStyles.header },
      { v: 'Acceptance Rate', t: 's', s: excelStyles.header },
      { v: 'Avg Attendance', t: 's', s: excelStyles.header },
      { v: 'Start Time', t: 's', s: excelStyles.header },
      { v: 'End Time', t: 's', s: excelStyles.header }
    ];

    const summaryData = [
      { v: stats.topic, t: 's', s: excelStyles.cell },
      { v: stats.totalParticipants, t: 'n', s: excelStyles.cell },
      { v: stats.attended.value, t: 'n', s: excelStyles.attended },
      { v: stats.notAttended.value, t: 'n', s: excelStyles.notAttended },
      { v: stats.accepted.value, t: 'n', s: excelStyles.accepted },
      { v: stats.attendanceRate.value, t: 's', s: excelStyles[stats.attendanceRate.style as keyof typeof excelStyles] },
      { v: stats.acceptanceRate.value, t: 's', s: excelStyles[stats.acceptanceRate.style as keyof typeof excelStyles] },
      { v: stats.avgAttendanceTime, t: 's', s: excelStyles.cell },
      { v: stats.startTime, t: 's', s: excelStyles.cell },
      { v: stats.endTime, t: 's', s: excelStyles.cell }
    ];

    const summaryWs = utils.aoa_to_sheet([summaryHeaders, summaryData]);
    utils.book_append_sheet(wb, summaryWs, 'Meeting Summary');

    // Attendee Details Sheet
    const attendeeHeaders = [
      { v: 'Topic', t: 's', s: excelStyles.header },
      { v: 'Name', t: 's', s: excelStyles.header },
      { v: 'Email', t: 's', s: excelStyles.header },
      { v: 'Status', t: 's', s: excelStyles.header },
      { v: 'Accepted', t: 's', s: excelStyles.header },
      { v: 'Join Time', t: 's', s: excelStyles.header },
      { v: 'Leave Time', t: 's', s: excelStyles.header },
      { v: 'Duration', t: 's', s: excelStyles.header },
      { v: 'Join Date', t: 's', s: excelStyles.header },
      { v: 'Leave Date', t: 's', s: excelStyles.header }
    ];

    const attendeeData = meetingInfo.attendees.map(attendee => {
      const row = generateAttendeeRow(attendee, meetingInfo);
      return [
        { v: row.topic, t: 's', s: excelStyles.cell },
        { v: row.name, t: 's', s: excelStyles.cell },
        { v: row.email, t: 's', s: excelStyles.cell },
        { v: row.status.value, t: 's', s: excelStyles[row.status.style as keyof typeof excelStyles] },
        { v: row.accepted.value, t: 's', s: excelStyles[row.accepted.style as keyof typeof excelStyles] },
        { v: (row.status.value != "Did Not Attend") ? extractTime(row.joinTime) : "N/A", t: 's', s: excelStyles.cell },
        { v: (row.status.value != "Did Not Attend") ? extractTime(row.leaveTime) : "N/A", t: 's', s: excelStyles.cell },
        { v: row.duration, t: 's', s: excelStyles.cell },
        { v: row.joinDate.split('at')[0].trim(), t: 's', s: excelStyles.cell },
        { v: row.leaveDate.split('at')[0].trim(), t: 's', s: excelStyles.cell }
      ];
    });

    const attendeeWs = utils.aoa_to_sheet([attendeeHeaders, ...attendeeData]);
    utils.book_append_sheet(wb, attendeeWs, 'Attendee Details');

    // Set column widths
    const colWidths = [
      { wch: 15 },  // Topic
      { wch: 20 },  // Name
      { wch: 30 },  // Email
      { wch: 15 },  // Status
      { wch: 12 },  // Accepted
      { wch: 15 },  // Join Time
      { wch: 15 },  // Leave Time
      { wch: 20 },  // Duration
      { wch: 20 },  // Join Date
      { wch: 20 }   // Leave Date
    ];

    attendeeWs['!cols'] = colWidths;
    summaryWs['!cols'] = colWidths;

    writeFile(wb, `meeting_${meetingInfo.meetingId}.xlsx`);
    onClose();
  };

  const downloadPDF = () => {
    const doc = new jsPDF('landscape') as unknown as AutoTableJsPDF;
    const stats = generateMeetingStats(meetingInfo);
    let currentY = 20;

    // Helper function to add page numbers after all content is added
    const addPageNumbers = () => {
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(
          `Page ${i} of ${totalPages}`,
          14,
          doc.internal.pageSize.height - 10
        );
      }
    };

    doc.setFontSize(20);
    doc.setTextColor(pdfStyles.header.color);
    doc.text('Meeting Summary', 14, currentY);
    currentY += 10;

    doc.setFontSize(12);
    doc.setTextColor(pdfStyles.subHeader.color);
    doc.text(`Topic: ${meetingInfo.interestName}`, 14, currentY);
    currentY += 7;
    doc.text(`Date: ${stats.startTime}`, 14, currentY);
    currentY += 8;

    // Summary Table
    doc.autoTable({
      startY: currentY,
      head: [[
        'Topic',
        'Total Participants',
        'Attended',
        'Not Attended',
        'Accepted',
        'Attendance Rate',
        'Acceptance Rate',
        'Avg Attendance',
        'Start Time',
        'End Time'
      ]],
      body: [[
        stats.topic,
        stats.totalParticipants.toString(),
        stats.attended.value.toString(),
        stats.notAttended.value.toString(),
        stats.accepted.value.toString(),
        stats.attendanceRate.value,
        stats.acceptanceRate.value,
        stats.avgAttendanceTime,
        stats.startTime.split(',')[1].trim(),
        stats.endTime.split(',')[1].trim(),
      ]],
      theme: 'grid',
      headStyles: {
        fillColor: pdfStyles.table.headerBg,
        textColor: pdfStyles.table.headerTextColor,
        fontSize: 10
      },
      columnStyles: {
        3: { fillColor: [254, 226, 226], textColor: [153, 27, 27] },
        2: { fillColor: [220, 252, 231], textColor: [22, 101, 52] },
        4: { fillColor: [254, 249, 195], textColor: [133, 77, 14] },
      }
    });

    currentY = (doc.lastAutoTable?.finalY || currentY) + 20;

    doc.setFontSize(16);
    doc.setTextColor(pdfStyles.header.color);
    doc.text('Attendee Details', 14, currentY);
    currentY += 5;

    // Filter out any attendees with missing data
    const validAttendees = meetingInfo.attendees.filter(
      attendee => attendee.attendeeName && attendee.attendeeEmail
    );

    const attendeeData = validAttendees.map(attendee => {
      const row = generateAttendeeRow(attendee, meetingInfo);
      const isAttended = row.status.value === 'Attended';
      return [
        '',
        row.topic,
        row.name,
        row.email,
        {
          content: row.status.value, styles: {
            textColor: isAttended ? [22, 163, 74] : [220, 38, 38],
            fillColor: isAttended ? [220, 252, 231] : [254, 226, 226],
          }
        },
        {
          content: row.accepted.value, styles: {
            textColor: row.accepted.value === 'Yes' ? [133, 77, 14] : [220, 38, 38],
            fillColor: row.accepted.value === 'Yes' ? [254, 249, 195] : [254, 226, 226],
          }
        },
        isAttended ? extractTime(row.joinTime) : "N/A",
        isAttended ? extractTime(row.leaveTime) : "N/A",
        row.duration,
        row.joinDate.split('at')[0].trim(),
        row.leaveDate.split('at')[0].trim(),
      ];
    });

    if (attendeeData.length > 0) {
      doc.autoTable({
        startY: currentY,
        head: [[
          'No.',
          'Topic',
          'Name',
          'Email',
          'Status',
          'Accepted',
          'Join Time',
          'Leave Time',
          'Duration',
          'Join Date',
          'Leave Date'
        ]],
        body: attendeeData as any,
        theme: 'grid',
        headStyles: {
          fillColor: pdfStyles.table.headerBg,
          textColor: pdfStyles.table.headerTextColor,
          fontSize: 10
        },
        styles: {
          fontSize: 9,
          cellPadding: 2,
          overflow: 'linebreak',
          lineWidth: 0.1
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 30 },
          2: { cellWidth: 25 },
          3: { cellWidth: 45 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 25 },
          7: { cellWidth: 25 },
          8: { cellWidth: 25 },
          9: { cellWidth: 25 },
          10: { cellWidth: 25 },
        },
        didParseCell: function (data) {
          if (data.section === 'body' && data.column.index === 0) {
            data.cell.text = [`${data.row.index + 1}`];
          }
        },
        rowPageBreak: 'avoid',
        bodyStyles: {
          minCellHeight: 15
        },
        margin: { top: 20, bottom: 20, left: 14, right: 14 },
        showFoot: 'lastPage'
      });
    }

    // Add page numbers after all content is added
    addPageNumbers();

    doc.save(`meeting_${meetingInfo.meetingId}.pdf`);
    onClose();
  };

  var downloadMenuItemClasses = "w-full text-left px-3 py-1.5 text-xs text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer transition-colors duration-150 first:rounded-t-md last:rounded-b-md";

  return (
    <div className="py-1 border border-gray-300 divide-y divide-gray-300" role="menu">
      <div
        data-download-action
        role="menuitem"
        className={downloadMenuItemClasses}
        onClick={(e) => handleDownload(downloadJSON, e)}
      >
        <FileJson size={14} className="mr-2 flex-shrink-0 text-gray-500" />
        <span className="whitespace-nowrap font-medium">Download JSON</span>
      </div>
      <div
        data-download-action
        role="menuitem"
        className={downloadMenuItemClasses}
        onClick={(e) => handleDownload(downloadCSV, e)}
      >
        <FileText size={14} className="mr-2 flex-shrink-0 text-gray-500" />
        <span className="whitespace-nowrap font-medium">Download CSV</span>
      </div>
      <div
        data-download-action
        role="menuitem"
        className={downloadMenuItemClasses}
        onClick={(e) => handleDownload(downloadExcel, e)}
      >
        <FileSpreadsheet size={14} className="mr-2 flex-shrink-0 text-gray-500" />
        <span className="whitespace-nowrap font-medium">Download Excel</span>
      </div>
      <div
        data-download-action
        role="menuitem"
        className={downloadMenuItemClasses}
        onClick={(e) => handleDownload(downloadPDF, e)}
      >
        <Database size={14} className="mr-2 flex-shrink-0 text-gray-500" />
        <span className="whitespace-nowrap font-medium">Download PDF</span>
      </div>
    </div>
  );
};

export default DownloadMenu;