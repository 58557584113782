export const CHART_COLORS = {
  total: {
    bg: 'rgba(99, 102, 241, 0.8)',
    text: 'rgb(99, 102, 241)',
    border: 'rgb(79, 70, 229)'
  },
  accepted: {
    bg: 'rgba(34, 197, 94, 0.8)',
    text: 'rgb(34, 197, 94)',
    border: 'rgb(22, 101, 52)'
  },
  notAttended: {
    bg: 'rgba(239, 68, 68, 0.8)',
    text: 'rgb(239, 68, 68)',
    border: 'rgb(153, 27, 27)'
  },
  attended: {
    bg: 'rgba(234, 179, 8, 0.8)',
    text: 'rgb(234, 179, 8)',
    border: 'rgb(133, 77, 14)'
  }
};