import { Avatar } from '@fluentui/react-avatar'
import { Subtitle1 } from '@fluentui/react-components'
import { DefaultButton } from '@fluentui/react/lib/Button'

import coffeestar from '../../assets/coffeestar.svg'
import { useAppStateStore } from '../../store'
import { NavLink } from 'react-router-dom'

export default function Greeting() {
  const appState = useAppStateStore()
  const isLargeScreen = window.innerWidth > 700
  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Avatar
              style={{
                width: isLargeScreen ? '7rem' : '4rem',
                height: isLargeScreen ? '7rem' : '4rem',
              }}
              name={appState.identity.name}
              image={{
                src: appState.profile.profilePicUrl,
              }}
              className={isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}
            />
          </div>

          <div style={{ paddingLeft: '1rem', display: 'flex', flexDirection: 'column' }}>
            <span className="ms-fontWeight-semibold">Hello,</span>
            <span>
              <Subtitle1>{appState.identity.name}</Subtitle1>
            </span>

            <span style={{ display: 'inline-flex', paddingTop: '0.7rem' }}>
              <NavLink to={'/preference'}>
                <DefaultButton text="Preferences" />
              </NavLink>
            </span>
          </div>
        </div>
        <img
          src={coffeestar}
          style={{
            paddingTop: isLargeScreen ? '1rem' : '0rem',
            width: isLargeScreen ? '400px' : '50%',
          }}
        />
      </div>
    </div>
  )
}
