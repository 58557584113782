import React, { useState } from 'react';
import { IMeetingInfo } from '../../types';
import AvatarGroup from './AvatarGroup';
import { ArrowDown } from 'lucide-react';

interface CardViewProps {
  meetings: IMeetingInfo[];
}

const INITIAL_LOAD = 20;
const LOAD_MORE_COUNT = 10;

const CardView: React.FC<CardViewProps> = ({ meetings }) => {
  const [displayCount, setDisplayCount] = useState(INITIAL_LOAD);

  const loadMore = () => {
    setDisplayCount(prev => Math.min(prev + LOAD_MORE_COUNT, meetings.length));
  };

  const displayedMeetings = meetings.slice(0, displayCount);
  const hasMore = displayCount < meetings.length;

  return (
    <div className="space-y-6">
      <div className="grid gap-x-9 gap-y-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 p-1">
        {displayedMeetings.map((meeting, index) => (
          <div key={meeting.meetingId} className="transform-gpu">
            <AvatarGroup
              meetingInfo={meeting}
              colorIndex={index}
            />
          </div>
        ))}
      </div>

      {hasMore && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMore}
            className="group bg-indigo-500 hover:bg-indigo-600 text-white px-2 py-1.5 rounded-full shadow-sm transition-all duration-200 text-xs"
          >
            <div className="flex items-center justify-center space-x-1.5">
              <div className="bg-white/20 px-1.5 py-0.5 rounded-full">
                {displayCount} / {meetings.length}
              </div>
              <span className="font-medium">Load More 10</span>
              <ArrowDown
                size={12}
                className="transform transition-transform duration-200 group-hover:translate-y-0.5"
              />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default CardView;