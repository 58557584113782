import { useEffect, useState } from 'react'
import React from 'react'
import { getProfilePic } from '../../services/graph/profile'
import Greeting from '../../pages/landingPage/Greeting'
import Meetings from './Meetings'
import WelcomeAdmin from '../../pages/landingPage/WelcomeAdmin'
import WelcomeUser from '../../pages/landingPage/WelcomeUser'
import toprightcurve from '../../assets/toprightcurve.svg'
import { useAppStateStore } from '../../store'
import { apiGet, apiPostResponse } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'

type OnboardedResult = {
  id: string
  name: string
  isFirstLogin: boolean
  isAdmin: boolean
  companyId: string
  isNewCompany: boolean
}

type UserDashboard = {
  id: string
  name: string
  upcomingMeetings: any[]
  pastMeetings: any[]
}

export function LandingPage() {
  const [dashboardResult, setDashboardResult] = React.useState<any>()
  const appState = useAppStateStore()
  const isLargeScreen = window.innerWidth > 700
  useEffect(() => {
    const ensureOnboarded = async () => {
      try {
        const onboardedResult = await apiPostResponse<OnboardedResult>(
          'landing-page/ensure-onboarded',
          appState.identity.accessToken,
          null
        )

        appState.setUserOnboardResult(onboardedResult)
        appState.setCompany(onboardedResult.companyId, onboardedResult.isNewCompany)

        let profilePicBlob = await getProfilePic(appState.identity.accessToken, onboardedResult.id, 120)
        const url = window.URL || window.webkitURL
        appState.setProfilePicUrl(url.createObjectURL(profilePicBlob))

        if (!onboardedResult.isNewCompany && !onboardedResult.isFirstLogin) {
          const dashboardResult = await apiGet<UserDashboard>(
            'landing-page/user-dashboard',
            appState.identity.accessToken
          )
          setDashboardResult(dashboardResult)
        }
      } catch (err: any) {
        logger.debug(err)
      }
    }

    ensureOnboarded()
  }, [])

  return (
    <div
      style={{ backgroundColor: '#EFF5F9', position: 'relative', paddingLeft: isLargeScreen ? '' : '2rem' }}
    >
      <div style={{ position: 'absolute', right: '0' }}>
        <img
          src={toprightcurve}
          style={{ width: isLargeScreen ? '100%' : '13rem' }}
          className={isLargeScreen ? 'ms-hiddenXxxl' : 'ms-hiddenXxlUp'}
        ></img>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: isLargeScreen ? 'row' : 'column',
          paddingTop: isLargeScreen ? '64px' : '0px',
          justifyContent: isLargeScreen ? 'space-evenly' : '',

          // justifyContent: 'space-evenly',
          // backgroundColor: mobile ? 'red' : 'blue',
        }}
      >
        {appState.identity && <Greeting />}

        {appState.identity && appState.company && appState.company.isNewCompany && appState.identity.isAdmin && (
          <WelcomeAdmin />
        )}

        {appState.identity && appState.identity.isFirstLogin && <WelcomeUser />}

        {appState.identity &&
          appState.company &&
          !appState.company.isNewCompany &&
          !appState.identity.isFirstLogin &&
          dashboardResult && (
            <Meetings upcomingMeetings={dashboardResult.upcomingMeetings} pastMeetings={dashboardResult.pastMeetings} />
          )}
      </div>
    </div>
  )
}
